import { login, logout, getUserInfo } from '@/api/common'
import { getStore, setStore, removeStore } from '@/utils/util'
import { constantRoutes } from "@/router"

const user = {
  state: {
    token: getStore("token"),
    userName: '',
    nickName: '',
    avatar: '',
    routes: constantRoutes,
    addRouters: [],
  },
  getters: {
    token: state => state.token,
    avatar: state => state.avatar,
    userName: state => state.userName,
    nickName: state => state.nickName,
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USERNAME: (state, userName) => {
      state.userName = userName
    },
    SET_NICKNAME: (state, nickName) => {
      state.nickName = nickName
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
  },
  actions: {
    // 登录
    Login({ commit }, form) {
      let params = {
        username: form.username.trim(),
        password: form.password
      }

      return new Promise((resolve, reject) => {
        login(params).then(res => {
          let data = res.data

          setStore("token", data.token);
          setStore("tokenHead", data.tokenHead);
          commit('SET_TOKEN', data.token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 获取用户信息
    getUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfo().then(res => {
          let data = res.data;

          setStore("userInfo", data)
          commit('SET_USERNAME', data.username)
          commit('SET_NICKNAME', data.nickName)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 退出系统
    LogOut({ commit }) {
      return new Promise((resolve, reject) => {
        logout().then(() => {
          commit('SET_TOKEN', '')
          commit('SET_USERNAME', '')
          commit('SET_NICKNAME', '')
          removeStore("token")
          removeStore("userInfo")
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
}

export default user

