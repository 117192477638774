import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { Locale } from 'vant';

// 导入语言包信息
import en from "./en.json";
import zhCN from "./zh-CN.json";
import zh from "./zh.json";

import enLocale from 'vant/es/locale/lang/en-US';
import zhLocale from 'vant/es/locale/lang/zh-CN';
import twLocale from 'vant/es/locale/lang/zh-TW';

// 注册i18n
Vue.use(VueI18n);

// 语言包根据语言环境分类
const messages = {
    'en_US': { ...en, ...enLocale },
    "zh_CN": { ...zhCN, ...zhLocale },
    "zh_TW": { ...zh, ...twLocale },
}

let lang = window.sessionStorage.getItem('lang');

switch (lang) {
    case "zh_CN":
        Locale.use('zh-CN', zhLocale);
        break
    case "zh_TW":
        Locale.use('zh-TW', twLocale);
        break
    case "en_US":
        Locale.use('en-US', enLocale);
        break
    default:
        //Locale.use('zh-TW', twLocale);
        Locale.use('zh-TW', twLocale);
        break
}

// 通过选项创建 VueI18n 实例并导出
export default new VueI18n({
    locale: window.sessionStorage.getItem('lang') || 'zh_TW', // 设置当前语言环境，默认中文繁体
    messages, // 设置语言环境对应信息
})

