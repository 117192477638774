import axios from 'axios';
import { getStore, removeStore } from '@/utils/util'
import { Toast } from 'vant';
import i18n from "@/lang";

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const service = axios.create({
	baseURL: "/api",
	timeout: 30000
});

// 是否显示重新登录
export let isRelogin = { show: false };

let lang = window.sessionStorage.getItem('lang') || 'zh_TW';

// request拦截器
service.interceptors.request.use(
	(config) => {
		let token = getStore("token");
		let tokenHead = getStore("tokenHead");

		if (token) {
			config.headers['Authorization'] = tokenHead + token
		}
		config.headers['Lang'] = lang;
		return config;
	},
	(error) => {
		console.log(error);
		return Promise.reject();
	}
);

// 响应拦截器
service.interceptors.response.use(
	(response) => {
		const code = response.data.code || 200;
		// 获取错误信息
		const msg = response.data.message || i18n.t('common.system_error');

		if (code === 401) {
			if (!isRelogin.show) {
				Toast(i18n.t('common.token_expired'));
				isRelogin.show = false;
				setTimeout(() => {
					removeStore("token")
					location.href = "/login/"
				}, 1000)
			}
			return Promise.reject(i18n.t('common.session_expried'))
		} else if (code == 500) {
			Toast(msg);
			return Promise.reject(new Error(msg))
		} else if (code !== 200) {
			Toast(msg);
			return Promise.reject('error')
		} else {
			return response.data
		}
	},
	(error) => {
		let { message } = error;

		if (message == "Network Error") {
			message = i18n.t('common.connect_error');
		}
		else if (message.includes("timeout")) {
			message = i18n.t('common.system_timeout');
		}
		else if (message.includes("Request failed with status code")) {
			message = i18n.t('common.system_api') + message.substr(message.length - 3) + i18n.t('common.exception');
		}
		Toast(message);
		return Promise.reject(error)
	}
);

export default service;
